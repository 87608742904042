import React from "react"
import Footer from "./footer"
import "../styles/layout.scss"
export default function Layout({ children }) {
  return (
    <div>
      {children}
      <Footer/>
    </div>
  )
}